import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useParams, Link } from 'react-router-dom';
import { ImArrowLeft2 } from 'react-icons/im';
import { useDispatch, useSelector } from 'react-redux';
import { FaPlus, FaSortDown } from 'react-icons/fa';
import { format, isSameDay } from 'date-fns';
import { getShiftsListAction, postShiftsFilteredAction } from '../../actions/shift';
import { getUserAction } from '../../actions/users';

import Header from '../../components/Header';

import styles from './UserPlanning.module.scss';
import useWindowSize from '../../hooks/useWindowSize';
import SelectDate from '../../components/SelectDate/SelectDate';
import TimeLineVertical from '../../components/TimeLineVertical/TimeLineVertical';
import { IShift } from '../../types/shift';
import ModalStandalone, { IModalHandle } from '../../lib/ModalStandalone';
import ShiftModal from '../../components/ShiftModal/ShiftModal';
import { SET_USERS_FILTERS } from '../../actions/types';
import DayRow from '../../components/DayRow';
import { postContractsFilteredAction } from '../../actions/contract';
import { getWeekNumber } from '../../utils';

const UserPlanning = () => {
  const userId = useParams()?.userId;
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const isMobile = width && width < 1044;
  const modalRef = useRef<IModalHandle | any>(null);

  const userAuth = useSelector((store: any) => store.authReducer)?.user;
  const { user, filters } = useSelector((store: any) => store.usersReducer);
  const { shiftsList, list } = useSelector((store: any) => store.shiftsReducer);

  const [prevWeek, setPrevWeek] = useState<Date[] | null>();

  const today = new Date(new Date().setUTCHours(0, 0, 0, 0));
  const date = new Date(filters.startDate);
  const dayOfWeek = new Date(filters.startDate).getDay();
  const diff = (dayOfWeek === 1) ? 0 : dayOfWeek - 1;
  const monday = diff === -1
    ? new Date(new Date(date).setDate(date.getDate() - 6))
    : new Date(new Date(date).setDate(date.getDate() - diff));
  const sunday = new Date(new Date(date).setDate(monday.getDate() + 6));
  const lastWeek = new Date(new Date(today).setDate(today.getDate() + 21));
  const last4Week = new Date(new Date(today).setDate(today.getDate() + 28));

  const displayNext = userAuth?.structure?.isNextWeeks
  || (!userAuth?.structure?.isNextWeeks && date.getTime() < lastWeek.getTime());

  const shiftFiltered = useMemo(() => shiftsList.filter((s: IShift) => {
    if (userAuth?.structure?.isNextWeeks) {
      return s;
    }
    if (s.isPublished) {
      return s;
    }
    if (new Date(s.date).getTime() < new Date(last4Week).getTime()) {
      return s;
    }
    return null;
  }), [shiftsList]);

  const dateFormated = filters?.startDate && new Intl.DateTimeFormat('fr', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(new Date(filters?.startDate));

  const mondayFormated = filters?.startDate && new Intl.DateTimeFormat('fr', {
    day: 'numeric',
    month: 'long',
  }).format(new Date(filters?.startDate));

  const sundayFormated = filters?.endDate && new Intl.DateTimeFormat('fr', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(new Date(filters?.endDate));

  function handleChangeFilters(obj: any) {
    dispatch({
      type: SET_USERS_FILTERS,
      payload: {
        ...filters,
        ...obj,
      },
    });
  }

  function handleStartDate(dir: string) {
    if (!filters?.startDate) {
      return null;
    }
    const startDate = new Date(`${format(new Date(filters.startDate), 'yyyy-MM-dd')}T00:00:00.000Z`);
    const endDate = filters?.endDate ? new Date(`${format(new Date(filters.endDate), 'yyyy-MM-dd')}T00:00:00.000Z`) : null;
    if (endDate) {
      if (dir === 'prev') {
        startDate.setDate(startDate.getDate() - 7);
        endDate.setDate(endDate.getDate() - 7);
      } else {
        startDate.setDate(startDate.getDate() + 7);
        endDate.setDate(endDate.getDate() + 7);
      }
    } else if (dir === 'prev') {
      startDate.setDate(startDate.getDate() - 1);
    } else {
      startDate.setDate(startDate.getDate() + 1);
    }
    return handleChangeFilters({
      startDate: startDate.toISOString(),
      endDate: endDate ? endDate.toISOString() : null,
    });
  }

  useEffect(() => {
    if (user?.structure?._id) {
      getShiftsListAction(dispatch, user?.structure?._id);
    }
  }, [user?.structure?._id]);

  useEffect(() => {
    if (userId) {
      getUserAction(dispatch, userId);
    }
  }, [userId]);

  useEffect(() => {
    postShiftsFilteredAction(dispatch, filters);
    postContractsFilteredAction(dispatch, filters);
  }, [filters]);

  useEffect(() => {
    if (!isMobile) {
      handleChangeFilters({
        startDate: monday.toISOString(),
        endDate: sunday.toISOString(),
      });
    } else {
      handleChangeFilters({
        startDate: filters?.startDate,
        endDate: null,
      });
    }
  }, [isMobile]);

  useEffect(() => {
    if (!isMobile) {
      if (filters?.startDate) {
        const daysOptions : Date[] = [];
        let current = 0;
        const end = 6;
        while (current <= end) {
          const newDate = new Date(new Date(filters.startDate)
            .setDate(new Date(filters?.startDate).getDate() + current));
          daysOptions.push(newDate);
          current += 1;
        }
        setPrevWeek(daysOptions);
      }
    }
  }, [filters?.startDate]);

  return (
    <div className={`${styles.container} ${userAuth.role === 'Member' ? styles.member : ''}`}>
      <Header name={userAuth.role !== 'Member' ? '' : 'Planning'} isProfile />
      {userAuth.role !== 'Member'
      && <>
        <Link
          className={styles.back}
          to={'/admin/planning'}
        >
          <ImArrowLeft2 /> <p>RETOUR AU Planning</p>
        </Link>
        </>
      }
      <div className={styles.content}>
      {userAuth.role !== 'Member'
        && <>
          <div className={styles.user}>
            <div className={styles.picture}>
              {user?.picture?.path
                ? <img src={`${process.env.REACT_APP_API_URL}/public/${user.picture.path}`} alt={user.picture.alt}/>
                : <p>
                  {user?.profile?.firstName[0]}.{user?.profile?.lastName[0]}
                </p>
              }
            </div>
            <p className={styles.name}>{user?.profile?.firstName} {user?.profile?.lastName}</p>
          </div>
          </>
        }
        <div className={styles.filters}>
          {isMobile
            && <div className={styles.row}>
              <div className={styles['select-date-mobile']}>
                <SelectDate />
              </div>
            </div>
          }
        </div>
        <div className={styles['mobile-filters']}>
          <h4>{dateFormated}</h4>
        </div>
        {isMobile
          && <div className={styles.list}>
            <TimeLineVertical
              shifts={shiftFiltered}
            />
          </div>
        }
        {!isMobile && shiftsList && prevWeek && prevWeek?.length > 0
          && <div className={styles.week}>
            <div className={styles.title}>
              <div className={styles['select-date']}>
                <button
                  type='button'
                  onClick={() => handleStartDate('prev')}
                >
                  <FaSortDown />
                </button>
                <h4>{`${mondayFormated} > ${sundayFormated}`}<br/>
                  Semaine {getWeekNumber(sunday)}
                </h4>
                {displayNext
                  && <button
                  type='button'
                  onClick={() => handleStartDate('next')}
                >
                  <FaSortDown />
                </button>
                }
              </div>
            </div>
            <div className={styles.labels}>
              <div className={styles.row}>
              </div>
              <div className={styles.row}>
                {list?.shifts?.map((s : any) => {
                  const label = s.label?.includes(':00') && s.label !== '02:00' && s.label !== user?.structure?.schedules?.end ? s.label : null;
                  return (
                  <div key={s.label}
                    style={{ width: `${100 / list.shifts.length}%` }}
                    className={styles.hour}
                  >
                    <p>{label}</p>
                  </div>
                  );
                })}
              </div>
            </div>
            <div className={styles.list}>
              <div className={styles.days}>
                {prevWeek?.map((d: Date) => <DayRow
                  user={user}
                  key={d.toISOString()}
                  shifts={shiftFiltered?.filter(
                    (s: IShift) => isSameDay(new Date(s.date), d) && s.user === user?._id,
                  )}
                  day={d}
                  isToday={d.toISOString() === today.toISOString()}
                />)}
              </div>
            </div>
          </div>
        }
      </div>
      {userAuth.role !== 'Member'
      && <>
      <button
        type="button"
        className={styles.new}
        onClick={() => modalRef.current.open()}
      >
        <div className={styles.icon}>
          <FaPlus />
        </div>
      </button>
      <ModalStandalone ref={modalRef} isDraggable={false}>
        <ShiftModal
          user={user}
        />
      </ModalStandalone>
      </>
      }
    </div>
  );
};

export default UserPlanning;
