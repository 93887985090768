import React, { useCallback, useEffect, useState } from 'react';
import { FaSortDown } from 'react-icons/fa';
import { BsCheck } from 'react-icons/bs';
import { FieldValues, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import styles from './ForecastForm.module.scss';
import { formattedDate, getWeeksOfYear } from '../../utils';
import CustomInputNumber from '../../lib/HooksFormFields/InputNumber';
import { createUserForecastAction, getUserForecastAction } from '../../actions/forecast';
import { IForecast } from '../../types/forecast';

export default function ForecastForm() {
  const userId = useParams()?.id || '';
  const dispatch = useDispatch();
  const currentYear = new Date().getFullYear() - 1;
  const [year, setYear] = useState(currentYear);
  const [months, setMonths] = useState<any>([]);
  const userAuth = useSelector((store: any) => store.authReducer)?.user;

  const {
    forecasts,
  } = useSelector((store: any) => store.usersReducer);

  const {
    handleSubmit, control, reset, watch, formState: { isDirty },
  } = useForm<FieldValues>();

  const getUserForecast = useCallback(async () => {
    await getUserForecastAction(dispatch, userId, year);
  }, [dispatch, userId, year]);

  async function onSubmit(values:FieldValues) {
    const forecastPayload : any = {
      user: userId,
      year,
      forecasts: [],
    };
    Object.keys(values).forEach((key) => {
      const hours = values[key];
      if (hours) {
        const [startDate, endDate, weekNumber] = key.split('/');
        forecastPayload.forecasts.push({
          hours,
          startDate,
          endDate,
          weekNumber,
        });
      }
    });
    await createUserForecastAction(dispatch, forecastPayload);
  }

  useEffect(() => {
    if (year) {
      getUserForecast();
      const monthsArray = getWeeksOfYear(year);
      setMonths(monthsArray);
    }
  }, [year, userId]);

  useEffect(() => {
    const form : FieldValues = {};
    forecasts.forEach((f: IForecast) => {
      const startDate = format(new Date(f.startDate), 'yyyy-MM-dd');
      const endDate = format(new Date(f.endDate), 'yyyy-MM-dd');
      const key : string = `${startDate}/${endDate}/${f.weekNumber}`;
      form[key] = f.hours;
    });
    reset(form);
  }, [forecasts]);

  return (
    <div className={styles.forecastForm}>
      <div className={styles.header}>
        <div className={styles.year}>
          <button
            type="button"
            onClick={() => setYear((y) => y - 1)}
          >
            <FaSortDown />
          </button>
          <h2>Année {year}-{year + 1}</h2>
          <button
            type="button"
            onClick={() => setYear((y) => y + 1)}
          >
            <FaSortDown />
          </button>
        </div>
        {isDirty
          && <button className={styles.submit}
            onClick={handleSubmit(onSubmit)}
          >
            <div className={styles.icon}>
              <BsCheck size={28} />
            </div>
            <span>Valider les modifications</span>
          </button>
        }
      </div>
      {months.map((m: any) => <div key={m.month}
        className={styles.month}
      >
        <div className={styles.label}>
          <h3>{m.month}</h3>
        </div>
        <div className={styles.weeks}>
          {m.weeks.map((w:any) => <div key={`${w.weekNumber}`} className={styles.week}>
              <div className={styles.col}>
                <p>
                  <span>Semaine {w.weekNumber}</span>
                  {' '}
                  {formattedDate(w.monday)} - {formattedDate(w.sunday)}
                </p>
              </div>
              <div className={styles.input}>
                {userAuth?.role === 'Manager'
                  ? <CustomInputNumber
                  control={control}
                  className="forecast"
                  name={`${w.monday}/${w.sunday}/${w.weekNumber}`}
                  rules={{}}
                />
                  : <p>
                     {watch(`${w.monday}/${w.sunday}/${w.weekNumber}`)
                       ? <span>{watch(`${w.monday}/${w.sunday}/${w.weekNumber}`)} heures</span>
                       : <span>---</span>
                    }
                  </p>
                }
              </div>
            </div>)}
        </div>
      </div>)}
    </div>
  );
}
