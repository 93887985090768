import { isSameDay } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { BsCheck } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { getMemberHomeAction, getShiftsListAction } from '../../actions/shift';
import DayRow from '../../components/DayRow';
import Header from '../../components/Header';
import TimeLine from '../../components/TimeLine/TimeLine';
import ValidationPanel from '../../components/ValidationPanel/ValidationPanel';
import Weather from '../../components/Weather/Weather';
import useWindowSize from '../../hooks/useWindowSize';
import { IShift } from '../../types/shift';

import styles from './MemberHome.module.scss';
import { getProfileAction } from '../../actions/auth';

const MemberHome = () => {
  const { width } = useWindowSize();
  const isMobile = width && width < 1024;
  const dispatch = useDispatch();
  const { home, list } = useSelector((store: any) => store.shiftsReducer);
  const { user } = useSelector((store: any) => store.authReducer);
  const [prevWeek, setPrevWeek] = useState<string[] | null>();
  const [validationIsOpen, setValidationIsOpen] = useState<boolean>(false);

  let today = new Date(new Date().setUTCHours(0, 0, 0, 0));
  today = new Date(today.toISOString());
  const tomorow = new Date(new Date(today).setDate(today.getDate() + 1));

  const dayOfWeek = new Date(today).getDay();
  const diff = (dayOfWeek === 1) ? 0 : dayOfWeek - 1;
  const monday = diff === -1
    ? new Date(new Date(today).setDate(today.getDate() - 6))
    : new Date(new Date(today).setDate(today.getDate() - diff));
  const previousMonday = new Date(new Date(today).setDate(monday.getDate() - 7));

  const dateFormated = new Intl.DateTimeFormat('fr', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(today);

  const todayShifts = home?.shifts?.filter((s :IShift) => s.date === today.toISOString());
  const tomorrowShifts = home?.shifts?.filter((s :IShift) => s.date === tomorow.toISOString());
  const isValid = home?.shifts?.filter(
    (s :IShift) => prevWeek?.find((p) => p === s.date),
  ).every((v: IShift) => v.isValid === true);

  useEffect(() => {
    getMemberHomeAction(dispatch);
    getProfileAction(dispatch);
    if (previousMonday) {
      const daysOptions : string[] = [];
      let current = 0;
      const end = 6;
      while (current <= end) {
        const newDate = new Date(new Date(previousMonday)
          .setDate(new Date(previousMonday).getDate() + current));
        newDate.setUTCHours(0, 0, 0, 0);
        daysOptions.push(newDate.toISOString());
        current += 1;
      }
      setPrevWeek(daysOptions);
    }
  }, []);

  useEffect(() => {
    if (user?.structure?._id) {
      getShiftsListAction(dispatch, user?.structure?._id);
    }
  }, [user?.structure?._id]);

  return (
    <div className={styles.container}>
      <Header name='OMADA' isProfile />
      <div className={styles.content}>
        <div className={styles.header }>
          <div className={styles.row}>
            <div className={styles.col}>
              <h1>{dateFormated}</h1>
              <Weather />
            </div>
            {!isMobile && home?.shifts
            && <>
              <div className={styles.col}>
                <label>Horaire de travail aujourd’hui</label>
                <div className={styles.hours}>

                </div>
                <div className={styles.timeline}>
                 {todayShifts.length > 0
                   ? <TimeLine
                    isHome={true}
                    shifts={todayShifts}
                  />
                   : <div className={styles.empty}>
                    <p>Repos</p>
                  </div>
                  }
                </div>
              </div>
              <div className={styles.col}>
                <label>Horaire de travail demain :</label>
                <div className={styles.hours}>

                </div>
                <div className={styles.timeline}>
                  {tomorrowShifts.length > 0
                    ? <TimeLine
                      isHome={true}
                      shifts={tomorrowShifts}
                    />
                    : <div className={styles.empty}>
                      <p>Repos</p>
                    </div>
                  }
                </div>
              </div>
            </>
            }
          </div>
          <div className={styles.row}>
            {/* <div className={styles.col}>
              <label>H annualisées</label>
              <p>{user?.contract?.hours && getDurationLabel(user?.contract?.hours)}</p>
            </div> */}
            <div className={styles.col}>
              <label>CP pris</label>
              <p>{user?.contract?.holidays}</p>
            </div>
            <div className={styles.col}>
              <label>Autres CP</label>
              <p>--</p>
            </div>
          </div>

        </div>
        {isMobile && home?.shifts
          && <div className={styles.shifts}>
            <div className={styles.row}>
              <div className={styles.col}>
                <label>Horaire de travail aujourd’hui</label>
                <div className={styles.hours}>

                </div>
                <div className={styles.timeline}>
                  {todayShifts.length > 0 ? <TimeLine
                    isHome={true}
                    shifts={todayShifts}
                  />
                    : <div className={styles.empty}>
                      <p>Repos</p>
                    </div>
                  }
                </div>
              </div>
              <div className={styles.col}>
                <label>Horaire de travail demain :</label>
                <div className={styles.hours}>

                </div>
                <div className={styles.timeline}>
                  {tomorrowShifts.length > 0 ? <TimeLine
                    isHome={true}
                    shifts={tomorrowShifts}
                  />
                    : <div className={styles.empty}>
                      <p>Repos</p>
                    </div>
                  }
                </div>

              </div>
            </div>
            {home?.shifts?.length > 0
            && <div className={styles.validation}>
              {!isValid
                ? <button
                  type="button"
                  onClick={() => setValidationIsOpen(true)}
                >
                  Valider ma semaine
                </button>
                : <div className={styles.valid}>
                  <div className={styles.icon}>
                    <BsCheck />
                  </div>
                  <p>Semaine validée</p>
                </div>
              }
            </div>
            }
          </div>
        }
        {!isMobile && home?.shifts && prevWeek && prevWeek?.length > 0
          && <div className={styles.week}>
            {home.shifts?.filter(
              (s: IShift) => prevWeek.find((d) => d === s.date),
            ).length > 0
            && <div className={styles.validation}>
              {!isValid
                ? <button
                  type="button"
                  onClick={() => setValidationIsOpen(true)}
                >
                  Valider ma semaine
                </button>
                : <div className={styles.valid}>
                  <div className={styles.icon}>
                    <BsCheck />
                  </div>
                  <p>Semaine validée</p>
                </div>
              }
            </div>
            }
            <div className={styles.title}>
              <p>Semaine passée</p>
            </div>
            <div className={styles.labels}>
              <div className={styles.row}>
              </div>
              <div className={styles.row}>
                {list?.shifts?.map((s : any) => {
                  const label = s.label?.includes(':00') && s.label !== '02:00' && s.label !== user?.structure?.schedules?.end ? s.label : null;
                  return (
                  <div key={s.label}
                    style={{ width: `${100 / list.shifts.length}%` }}
                    className={styles.hour}
                  >
                    <p>{label}</p>
                  </div>
                  );
                })}
              </div>
            </div>
            <div className={styles.list}>
              <div className={styles.days}>
                {prevWeek?.map((d: string) => <DayRow
                  user={user}
                  key={d}
                  shifts={home.shifts?.filter((s: IShift) => {
                    if (isSameDay(new Date(s.date), d)) {
                      return s;
                    }
                    return null;
                  })}
                  day={new Date(d)}
                />)}
              </div>
            </div>
          </div>
        }
      </div>
      <ValidationPanel
        isOpen={validationIsOpen}
        prevWeek={prevWeek?.map((d) => new Date(d)) || []}
        shifts={home?.shifts || []}
        close={() => setValidationIsOpen(false)}
      />
    </div>
  );
};

export default MemberHome;
